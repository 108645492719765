//
// Project Variables
// --------------------------------------------------
@import "node_modules/@relayter/rubber-duck/src/sass/variables";

// opacity
$color_black-30: rgba(0, 0, 0, 0.3);

// Body background
$background-gradient: linear-gradient(to right, #3fba87, #1f4f69);


$side-menu-width: 78px;
$border-radius: .25rem;

// Navbar
$header-height: 36px;

// Shadows
$sub-header-box-shadow: 0 7px 14px 1px rgba(239, 238, 239, 0.55);
$card-image-box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.08);

// Workflow indicator sizes
$workflow-indicator-height: 117px;
$workflow-indicator-step-circle-size: 36px;
$workflow-indicator-step-width: 80px;
$workflow-indicator-max-transition-width: 50px;
$workflow-indicator-line-size: 1px;
$workflow-indicator-line-color: $color-snow;
$workflow-indicator-counter-size: 24px;
$workflow-indicator-step-icon-size: 18px;

// Workflow actions height
$workflow-actions-container-height: 57px;

// Workflow layout side bar
$sidebar-layout-width: 394px;

// Workflow tab row height
$workflow-tab-row-height: 41px;

// Workflow working area height
$workflow-working-area-height: calc(100vh - #{$workflow-indicator-height} - #{$header-height} - #{$workflow-tab-row-height});

// Item selection width
$item-selection-width: 139px;

// Default form width
$form-width: 450px;
$form-item-overview-width: 816px;

// Default search bar width
$search-bar-width: 450px;
$dropdown-width: 225px;

// Workflow inline form width
$inline-form-width: 546px;

$user-icon-size: 25px;

$tab-row-height: 48px;

$button-bar-height: 52px;

$data-field-icon-width: 31px;

// Overlay button template container

$overlay-button-max-height: 444px;

// Hr config
$hr-border-bottom: 1px solid $color-snow;
$hr-tab-bar: 0 auto $spacer-base auto;
$nuc-tab-bar-height: 48px;

$max-dialog-content-height: 370px; // avoid dialog scrollbar

// Width of the sidebar on the right side
$right-sidebar-width: 325px;

$note-pin-width: 40px;
$list-note-pin-height: 20px;
$side-bar-note-pin-height: 26px;

$table_section_header_height: 20px; // used when you have 2 tables top: showing selection. bot: showing all items you can select.

// progress bar
$progress-bar-padding: $spacer-base 0 $spacer-small 0
