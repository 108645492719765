@import "_variables.scss";

.mat-mdc-menu-panel.menu-items-container {
    margin-top: $spacer-small;
    min-height: $input-height;
    max-width: unset;
    .mat-mdc-menu-content {
        padding: 0;

        .mat-mdc-menu-item {
            padding: 0 $spacer-base;
            height: $input-height;
            line-height: $input-height;
            min-height: 32px;
        }
    }
}
