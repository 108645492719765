/* You can add global styles to this file, and also import other style files */
// Variables
@import "sass/_variables.scss";

// Preload loading indicator style
@import "sass/preload.scss";

@import "sass/smooth-animated";

// Material progress bar
@import 'sass/mat-progresss-bar.scss';

// Material menu styling
@import 'sass/mat-menu.scss';

//Customizing material components with relayter theme
[type='submit'], [type='button'], [type='reset'] {
    appearance: none !important;
}

.cdk-overlay-backdrop.menu-backdrop {
    position: absolute;
    left: $side-menu-width;
    background-color: transparent;
}

.filters-overlay-panel {
    background: $color-white;
    border: 1px solid $color-snow;
    border-radius: 3px;
    box-shadow: $box-shadow-regular;
    max-height: $overlay-button-max-height;
    overflow-y: scroll;
    margin-bottom: 12px; // to counter the translateY offset and prevent the bottom being clipped by the window
}

// START Disable intercom button by force, until intercom fixes their shit.
.intercom-launcher-frame, .intercom-lightweight-app-launcher {
    display: none !important;
}

.intercom-app {
    div:nth-child(2) {
        pointer-events: none !important; // prevent hidden div from blocking actions in the bottom of the table.
    }
}

.intercom-messenger-frame {
    z-index: 1;
}
// END INTERCOM fix

hr {
    border: 0;
    border-bottom: $hr-border-bottom;
}

*, :after, :before {
    box-sizing: inherit;
}

a {
    text-decoration: none;
}

@keyframes animateBg {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 0;
    }
}

@keyframes active-route-animation {
    0% {
        transform: scale(0, 0);
    }
    90% {
        transform: scale(1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

.animated-bg {
    background: $gradient-snow-storm;
    background-size: 300% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
    animation: animateBg 3s infinite linear;
}

.mat-accent .mat-slider-track-fill {
    background-color: $color_green !important;
}

.mat-slider-thumb-label {
    background-color: $color_green !important;

}

.mat-slider-thumb {
    background-color: $color_green !important;
}

.variant-highlight {
    color: $color-black;
    i {
        color: $color-blue-royal;
    }
}

.variant-info {
    color: $color-dark-grey;
}
